<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="$router.back()"></i>
          </router-link>
        </li>
        <li>{{ currentCoinPayChannel.coinName }}</li>
      </ul>
    </div>
    <div class="main main_page">
      <AForm
        layout="vertical"
        :model="formModel"
        ref="formRef"
        :rules="ruleRef"
        validateTrigger="change"
        @submit.prevent="handleRecharge"
        autocomplete="off"
      >
        <div class="Pay_usdt">
          <div class="Pay_usdt_box">
            <h1>
              {{t('extra.rechageText1')}}
            </h1>
          </div>

          <div class="Pay_usdt_box" v-if="currentCoinPayChannel.giftRatio > 0">
            <span>{{t('common.rechargeWay.GiftTitle')}}</span>
            <h1>
              {{  +formModel.giftStrategy === 1?t('common.rechargeWay.GiftDetail'):t('common.rechargeWay.GiftDetail2')}}
              <span class="giftSize">{{ currentCoinPayChannel.giftRatio+'%' }}</span>
            </h1>
          </div>

          <div class="Pay_usdt_box">
            <p>
              <b>{{t('extra.coinType')}} &nbsp;</b>
              <span>{{ currentCoinPayChannel.coinType }}</span>
            </p>
            <p>
              <b>{{t('extra.channel')}}: &nbsp;</b>
              <span>{{ currentCoinPayChannel.coinChannel }}</span>
            </p>
            <p>
              <b>{{t('extra.rate')}}: &nbsp;</b>
              <span>{{ formModel.rate }}</span>
            </p>
            <p>
              <b>{{t('profile.label.address')}}: &nbsp;</b>
              <span>{{ formModel.address }}</span>
              &nbsp;
              <button type="button" @click.prevent="doCopy(formModel.address)">
                {{ t("common.button.copy") }}
              </button>
            </p>
            <p class="warning">
              {{t('extra.rechageText2')}}
            </p>
          </div>
        </div>

        <div class="Pay_usdt_box">
          <ul>
            <li>
              {{ t("extra.maxAmount")+': '
              }}{{ formatMoney(currentCoinPayChannel.maxMoney) }}
            </li>
            <li>
              {{ t("extra.minAmount")+': '
              }}{{ formatMoney(currentCoinPayChannel.minMoney) }}
            </li>
          </ul>
          <ul>
            <h3>{{t('extra.coinAmount')}}:</h3>
            <li>
              <AFormItem name="coinAmount">
                <AInput
                  v-model:value="formModel.coinAmount"
                  :placeholder="t('common.placeholder.coinAmount')"
                  type="number"
                  step="any"
                />
              </AFormItem>
            </li>
          </ul>
          <ul>
            <h3>{{t('profile.label.amount')}}:</h3>
            <li>
              <AFormItem name="amount">
                <AInput
                  :value="formatMoney(+formModel.amount)"
                  placeholder=""
                  :disabled="true"
                  type="text"
                />
              </AFormItem>
            </li>
          </ul>
          <ul>
            <h3>{{t('extra.remark')}}:</h3>
            <li>
              <AFormItem name="remark">
                <AInput
                  v-model:value="formModel.remark"
                  :placeholder="t('common.placeholder.remark')"
                />
              </AFormItem>
            </li>
            <li class="li_Upload">
              <div class="Upload">
                <Upload
                  action="/sys/upload/file"
                  list-type="picture-card"
                  accept="image/png,image/jpeg"
                  v-model:file-list="images"
                  @change="handleUploadChange"
                  :show-upload-list="false"
                >
                  <img
                    v-if="!imgIsLoading && images.length > 0"
                    :src="images?.[0]?.response?.data?.url"
                    alt="avatar"
                    class="imgClass"
                  />
                  <div v-else>
                    <loading-outlined v-if="loading"></loading-outlined>
                    <plus-outlined v-else></plus-outlined>
                    <div class="ant-upload-text">{{t('common.button.upload')}}</div>
                  </div>
                </Upload>
              </div>
            </li>
          </ul>
        </div>

        <div class="Sys_submit_btn recharge">
          <button type="submit">{{ t("common.button.submit") }}</button>
        </div>
      </AForm>
    </div>
    <Loading :loading="loading" />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  getCurrentInstance,
  watch,
} from "vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
import { Upload, message, Modal } from "ant-design-vue";
import { useI18n } from "@/lang";
import api from "@/api";
import { currentCoinPayChannel } from "@/hooks/currentCoinPayChannel";
import { copyText } from "vue3-clipboard";
import Loading from "@/components/Loading.vue";
import { formatMoney } from "@/utils/formatter";

export default defineComponent({
  name: "RechargePay",
  components: { Upload, PlusOutlined, Loading },
  setup() {
    onMounted(() => {
      console.log(currentCoinPayChannel,'22222')
      formModel.rate = currentCoinPayChannel?.value?.coinRate;
      formModel.channelId = currentCoinPayChannel?.value?.id;
      formModel.address = currentCoinPayChannel?.value?.address;
      formModel.giftStrategy = currentCoinPayChannel?.value?.giftStrategy;
    });
    const formRef = ref(null);
    const { proxy } = getCurrentInstance();
    const loading = ref(false);
    const router = useRouter();
    const imgIsLoading = ref(false);
    const { t } = useI18n();

    const formModel2 = reactive({
      isShowGift: false
    });

    const formModel = reactive({
      amount: 0,
      coinAmount: 0,
      images: [],
      rate: null,
      channelId: null,
      remark: "",
      address: "",
      giftStrategy:null
    });

    const images = ref([]);
    watch(formModel, (newVal) => {
      formModel.amount = formatMoney(+newVal.coinAmount * +newVal.rate, false);
      console.log(formModel.amount);
    });
    const ruleRef = reactive({
      coinAmount: [
        {
          required: true,
          message: t("profile.placeholder.rechargeAmount"),
        },
        {
          validator: async (rule, value) => {
            let coinAmount_ = "" + value;
            if (!coinAmount_) {
              return Promise.resolve();
            }
            let pointIndex = coinAmount_.indexOf(".");
            if (pointIndex != -1) {
              let dec = coinAmount_.slice(pointIndex);
              if (dec.length > 5) {
                return Promise.reject(t("error.recharge.decimalErr4"));
              }
            }
            if (
              +coinAmount_ * +formModel.rate >
              currentCoinPayChannel?.value?.maxMoney
            ) {
              return Promise.reject(t("error.recharge.noMatchLimit"));
            }
            if (
              +coinAmount_ * +formModel.rate <
              currentCoinPayChannel?.value?.minMoney
            ) {
              return Promise.reject(t("error.recharge.noMatchLimit"));
            }
            return Promise.resolve();
          },
        },
      ],
    });

    function handleUploadChange(info) {
      if (info.file.status === "uploading") {
        imgIsLoading.value = true;
        message.loading({
          content: "uploading",
          key: "recharge",
        });
      }

      if (info.file.status === "done") {
        imgIsLoading.value = false;
        formModel.images[0] = info.file.response.data.key;
        images.value = images.value.slice(-1);
        message.destroy();
      }

      if (info.file.status === "error") {
        imgIsLoading.value = false;
        message.error({
          content: t("error.imageUploadError"),
          key: "recharge",
          duration: 3,
        });
      }
    }

    async function handleRecharge() {
      let validateResult = await proxy.$refs["formRef"]
        .validate()
        .then((e) => {
          return e;
        })
        .catch((e) => {
          return e;
        });
      if (
        !(
          validateResult &&
          validateResult.errorFields &&
          validateResult.errorFields.length === 0
        )
      ) {
        return;
      }
      if (formModel.images.length < images.value.length) {
        return;
      }
      if (formModel.images.length === 0) {
        message.error({
          content: t("error.recharge.screenshotsRequired"),
          key: "recharge",
          duration: 3,
        });
        return;
      }
      if (!formModel.channelId) {
        return;
      }

      try {
        loading.value = true;
        let formToSend = {
          amount: formModel.amount,
          payImg: formModel.images[0],
          coinAmount: formModel.coinAmount,
          rate: formModel.rate,
          channelId: formModel.channelId,
          remark: formModel.remark,
        };
        let res = await api.coinPayCreate(formToSend);
        if (res && res.data && res.data.success) {
          Modal.success({
            title: t("common.title.rechargeOrderSubmitted"),
            width: "80%",
            centered: true,
            content: t("profile.hits.rechargeOrderSubmitted"),
            okText: t("common.button.goBack"),
            onOk: async () => {
              await router.replace("/home");
            },
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    }

    function doCopy(text) {
      copyText(text, undefined, (error, event) => {
        console.log(event);
        if (!error) {
          message.success({
            key: "copy",
            content: t("common.clipboard.success"),
          });
        }
      });
    }

    return {
      formModel2,
      t,
      formRef,
      formModel,
      ruleRef,
      handleRecharge,
      PlusOutlined,
      images,
      handleUploadChange,
      doCopy,
      loading,
      imgIsLoading,
      currentCoinPayChannel,
      formatMoney,
    };
  },
});
</script>

<style lang="scss">
.Pay_usdt_box {
  b {
    font-size: 16px;
  }
  .warning{
    margin-top: 10px;
    font-size:12px;
    color:red;
  }
}
#rechargeeBankRadioGroup {
  box-sizing: border-box;
  padding: 0px 5px;
  width: 100vw;
  margin-top: 10px;
  .ant-radio-wrapper {
    margin-bottom: 10px;
  }
}
.RechargeBankD {
}
.imgClass {
  width: 90px;
  height: 90px;
}
</style>
